<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router/index.js";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import {reactive} from "vue";
import { format } from "date-fns";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Historique locations",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "HISTORIQUE DU VEHICULE 'IMMATRICULATION' ",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      ordersData: [],
      dataUser: [
        {
          immatricule: "AXCFXFXF",
          model: "FTRDRTSERT",
          marque: "TOYOTA",
          assurance: "oui",
          status: "actif",
        },
        {
          immatricule: "AXCFXFXF",
          model: "FTRDRTSERT",
          marque: "TOYOTA",
          assurance: "oui",
          status: "actif",
        },
        {
          immatricule: "AXCFXFXF",
          model: "FTRDRTSERT",
          marque: "TOYOTA",
          assurance: "oui",
          status: "actif",
        },
        {
          immatricule: "AXCFXFXF",
          model: "FTRDRTSERT",
          marque: "TOYOTA",
          assurance: "oui",
          status: "actif",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,

      fields: [
        { key: "immatricule", sortable: true, label: "Date de début" },
        { key: "model", sortable: true, label: "Date prévu de fin" },
        { key: "marque", sortable: true, label: "Date de fin " },
        { key: "assurance", sortable: true, label: "Assurance" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "client", sortable: true, label: "Client" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      passager: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      tokenUser: JSON.parse(sessionStorage.getItem("user")).tokenSalt,
      idUser: 0,
      fisrtname: "",
      lastname: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      
      fileProfil: null,
      filePermis: null,
      fileMoto: null,
      fileCni: null,
      user: {
        username: "",
        usertype: "",
        tel: "",
        email: "",
        levelRate: "",
        level: "",
        rating: "",
        active: "",
        badge: "",
      },
      preference: {
        acceptHandicape: "",
        discutIncar: "",
        eatIncar: "",
        musicIncar: "",
        smoke: "",
        stop: "",
      },
      verify: {
        email: false,
        permis: false,
        autoMoto: false,
        image: false,
        tel: false,
        cni: false,
      },
      activity: {
        publies: 0,
        annulees: 0,
        a_venir: 0,
        effectues: 0,
        gains: 0,
        reservations_annulees: 0,
        voyages_effectues: 0,
        reservations_effectuees: 0,
      },
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      showretire: false,
      showSucess: false,
      showEchec: false,
      userDesac: null,
      avislaisses: [],
      avisrecus: [],
      conducteurData: [],
      passagerData: [],
      img: "",
      vue: false,
      visible: false,
      showModalprofil: false,
      showModalcni: false,
      showModalpermis: false,
      showModalautoMoto: false,
      HistoriqueConducteur: false,
      HistoriquePassager: false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
      vehicule: {
      agence: "",
      marque: "",
      types: "",
      model: "",
      climatisation: "",
      motorisation: "",
      nombreSieges: "",
      numeroAssurance: "",
      compagnieAssurance: "",
      observations: "",
      statut: "",
      anneeMiseEnCirculation: "",
      couleur: "",
      immatriculation: "",
      kmAuCompteur: "",
      lieu: "",
    },
    };
  },
  validations: {
    firstname: { required },
    lastname: { required },
    genre: { required },
    number_phone: { required, numeric },
    email: { required, email },
    password: { required },

    tooltipform: {
      fname: { required },
      lname: { required },
      username: { required },
      city: { required },
      state: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
    
  },

  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //console.log("tokenUser:", this.tokenUser);
      this.idUser = this.$route.params.row;
      console.log("id:", this.idUser);
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/rmo/vehicule/?id=" + this.idUser,
        false
      );

      console.log("data.data:", data.data);
      if (data && data.data) {
        //console.log("data.data:", data.data);
        this.vehicule.agence = data.data.agence.designation;
        this.vehicule.climatisation = data.data.climatisation;
        this.vehicule.model = data.data.model;
        this.vehicule.marque = data.data.marque;
        this.vehicule.numeroAssurance = data.data.numeroAssurance;
        this.vehicule.kmAuCompteur = data.data.kmAuCompteur;
        this.vehicule.compagnieAssurance = data.data.compagnieAssurance;
        this.vehicule.couleur = data.data.couleur;
        this.vehicule.types = data.data.types;
        this.vehicule.statut = data.data.statut;
        this.vehicule.observations = data.data.observations;
        this.vehicule.nombreSieges = data.data.nombreSieges;
        this.vehicule.motorisation = data.data.motorisation;

        if (data.data.userAvatarCode !== null) {
          this.allImage.avatar =
            `https://174.138.181.206/admin/image/?codeImage=` +
            data.data.userAvatarCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.avatar = require("@/assets/images/users/attente.png");
        }

        if (data.data.userCniCode) {
          this.allImage.cni =
            `https://174.138.181.206/admin/image/?codeImage=` +
            data.data.userCniCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.cni = require("@/assets/images/users/attente.png");
        }

        if (data.data.vehicule) {
          if (
            data.data.vehicule.autoMotoPermisCode !== null &&
            this.tokenUser !== null
          ) {
            this.allImage.permis =
              `https://174.138.181.206/admin/image/?codeImage=` +
              data.data.vehicule.autoMotoPermisCode +
              `&token=` +
              this.tokenUser;
          } else {
            this.allImage.permis = require("@/assets/images/users/attente.png");
          }
        } else {
          this.allImage.permis = require("@/assets/images/users/attente.png");
        }

        if (data.data.vehicule) {
          if (data.data.vehicule.autoMotoPictureCode !== null) {
            this.allImage.vehicule =
              `https://174.138.181.206/admin/image/?codeImage=` +
              data.data.vehicule.autoMotoPictureCode +
              `&token=` +
              this.tokenUser;
          } else {
            this.allImage.vehicule = require("@/assets/images/users/attente.png");
          }
        } else {
          this.allImage.vehicule = require("@/assets/images/users/attente.png");
        }
      }

      const dataActivity = await apiRequest(
        "GET",
        "admin/trajets/activites/?id=" + this.idUser,
        undefined,
        false
      );
      //console.log("data.activity:", dataActivity.data);

      if (dataActivity && dataActivity.data) {
        console.log(dataActivity.data);
        this.activity.publies = dataActivity.data.conducteur.publie
          ? dataActivity.data.conducteur.publie
          : "0";
        this.activity.annulees = dataActivity.data.conducteur.annules
          ? dataActivity.data.conducteur.annules
          : "0";
        this.activity.a_venir = dataActivity.data.conducteur.avenir
          ? dataActivity.data.conducteur.avenir
          : "0";
        this.activity.effectues = dataActivity.data.conducteur.effectues
          ? dataActivity.data.conducteur.effectues
          : "0";
        this.activity.gains = dataActivity.data.conducteur.gains
          ? dataActivity.data.conducteur.gains
          : "0";
        this.activity.reservations_effectuees = dataActivity.data.passager
          .reserEffectues
          ? dataActivity.data.passager.reserEffectues
          : "0";
        this.activity.reservations_annulees = dataActivity.data.passager
          .reserAnnules
          ? dataActivity.data.passager.reserAnnules
          : "0";
        this.activity.voyages_effectues = dataActivity.data.passager
          .voyagesEffectues
          ? dataActivity.data.passager.voyagesEffectues
          : "0";
      }

      const avisData = await apiRequest(
        "GET",
        "admin/avis/?id=" + this.idUser,
        undefined,
        false
      );

      //console.log("avis:",avisData.data);
      if (avisData && avisData.data) {
        this.avislaisses = avisData.data.avisEmis;
        this.avisrecus = avisData.data.avisRecu;
      }

      const historiqueAdmin = await apiRequest(
        "GET",
        "admin/trajets/historique/?id=" + this.idUser,
        undefined,
        false
      );

      //console.log("historique admin:", historiqueAdmin.data)
      if (
        historiqueAdmin &&
        historiqueAdmin.data &&
        historiqueAdmin.data.conducteur
      ) {
        const historiqueConducteurTable = historiqueAdmin.data.conducteur.map(
          (historique) => {
            return {
              trajet: historique.lieuDepart + " >> " + historique.lieuArrivee,
              date: format(
                new Date(historique.dateHeureDepart),
                "dd-MM-yyyy hh:mm:ss"
              ),
              prix: historique.prix,
              statut: historique.statutTrajet,
            };
          }
        );
        this.conducteurData = historiqueConducteurTable;
        //console.log('this.conducteurData:', this.conducteurData)
      }

      if (
        historiqueAdmin &&
        historiqueAdmin.data &&
        historiqueAdmin.data.passager
      ) {
        const historiquePassagerTable = historiqueAdmin.data.passager.map(
          (historique) => {
            return {
              trajet: historique.lieuDepart + " >> " + historique.lieuArrivee,
              date: format(
                new Date(historique.dateHeureDepart),
                "dd-MM-yyyy hh:mm:ss"
              ),
              prix: historique.prix,
              statut: historique.statutTrajet,
            };
          }
        );
        this.passagerData = historiquePassagerTable;
        //console.log('this.conducteurData:', this.conducteurData)
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    verification: function() {
      console.log("ok");
    },

    ValidationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    Submit: function() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    toogleShowPassword() {
      var show = document.getElementById("password");
      if (this.showPassword == false) {
        this.showPassword = true;
        show.type = "text";
      } else {
        this.showPassword = false;
        show.type = "password";
      }
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    showSingle() {
      this.imgs = require("@/assets/images/small/img-2.jpg");
      this.show();
    },
    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
      console.log(this.imgs);
      this.show();
    },
    show() {
      this.visible = true;
    },

    handleFileProfil() {
      this.fileProfil = this.$refs.fileProfil.files[0];
    },
    handleFilePermis() {
      this.filePermis = this.$refs.filePermis.files[0];
    },
    handleFileMoto() {
      this.fileMoto = this.$refs.fileMoto.files[0];
    },
    handleFileCni() {
      this.fileCni = this.$refs.fileCni.files[0];
    },

    async verifyPictureRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    goToDetail(row) {
      console.log("row:", row);
      router.push(`/vehicule/historique/${row}`);
      //router.push('/user/user_info/'+row.id)
    },
    async verifyPictureAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyCniRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyCniAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPermisRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPermisAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyVehiculeRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyVehiculeAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyAvisRefuse(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 0 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    async verifyAvisAccepte(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 1 },
        false
      );

      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    async desactiverUser() {
      const desacUser = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.idUser },
        false
      );

      if (desacUser && desacUser.data) {
        this.init();
        //console.log("Test:",this.userDesac)
      }
    },
    async ActiverUser() {
      const activUser = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.idUser },
        false
      );

      if (activUser && activUser.data) {
        this.init();
      }
    },
    async attribuerBadge() {
      const badge = await apiRequest(
        "POST",
        "admin/valider-user",
        {
          idUser: this.idUser,
          attribuer: true,
        },
        false
      );
      if (badge && badge.data) {
        this.init();
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }
    },
    async retirerBadge() {
      const retirer = await apiRequest(
        "POST",
        "admin/valider-user",
        {
          idUser: this.idUser,
          attribuer: false,
        },
        false
      );
      if (retirer && retirer.data) {
        this.init();
        this.showretire = true;
      } else {
        this.showEchec = true;
      }
    },
    async addCNI() {
      this.showModalcni = false;
      if (this.fileCni) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("cni", this.fileCni);

        const returnFilecni = await apiRequest(
          "POST",
          "admin/user-cni-pic",
          formData,
          true
        );

        if (returnFilecni && returnFilecni.data) {
          this.init();
          console.log("user:", this.idUser);
          console.log("CniImage:", returnFilecni);
        }
      }
    },
    async addProfil() {
      this.showModalprofil = false;
      if (this.fileProfil) {
        let formData = new FormData();
        formData.append("id", this.idUser);
        formData.append("avatar", this.fileProfil);

        const returnFileProfil = await apiRequest(
          "POST",
          "admin/user-pic",
          formData,
          true
        );

        if (returnFileProfil && returnFileProfil.data) {
          console.log("Profil", returnFileProfil.data);
          this.init();
        }
      }
    },
    async addPermis() {
      this.showModalpermis = false;
      if (this.filePermis) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("file", this.filePermis);

        const returnFilePermis = await apiRequest(
          "POST",
          "admin/user-permis-pic",
          formData,
          true
        );

        if (returnFilePermis && returnFilePermis.data) {
          console.log("Permis", returnFilePermis.data);
          this.init();
        }
      }
    },
    async addAutoMoto() {
      this.showModalautoMoto = false;
      if (this.fileMoto) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("file", this.fileMoto);

        const returnFileAutoMoto = await apiRequest(
          "POST",
          "admin/user-automoto-pic",
          formData,
          true
        );

        if (returnFileAutoMoto && returnFileAutoMoto.data) {
          console.log("AutoMoto", returnFileAutoMoto.data);
          this.init();
        }
      }
    },
  },

  CloseModalProfil() {
    this.showModalprofil = false;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h3 class="mb-5">Photos du véhicule</h3>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <div>
                      <h5 class="text-center">Vue de face</h5>
                      <a class="image-popup-vertical-fit" @click="showImage">
                        <img
                          class="avatar"
                          alt
                          :src="`${this.allImage.avatar}`"
                          width="250"
                          height="200"
                        />
                      </a>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <h5 class="text-center">Intérieure 1</h5>
                      <a class="image-popup-vertical-fit" @click="showImage">
                        <img
                          class="avatar"
                          alt
                          :src="`${this.allImage.cni}`"
                          width="250"
                          height="200"
                        />
                      </a>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <h5 class="text-center">Itérierieur 2</h5>
                      <a class="image-popup-vertical-fit" @click="showImage">
                        <img
                          class="avatar"
                          alt
                          :src="`${this.allImage.permis}`"
                          width="250"
                          height="200"
                        />
                      </a>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div>
                      <h5 class="text-center">Vue de derrière</h5>
                      <a class="image-popup-vertical-fit" @click="showImage">
                        <img
                          class="avatar"
                          alt
                          :src="`${this.allImage.vehicule}`"
                          width="250"
                          height="200"
                        />
                      </a>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                <h3 class="mb-5">Informations sur le véhicule</h3>
              </div>
              <b-card
                header-class="bg-transparent border-primary"
                class="border border-info"
              >
                <div class="row">
                  <div class="col-md-4">
                    <div class="mt-2">Agence : {{ this.vehicule.agence }}</div>
                    <div class="mt-2">Marque : {{ this.vehicule.marque }}</div>
                    <div class="mt-2">
                      Kilométrage:{{ this.vehicule.kmAuCompteur }}
                    </div>
                    <div class="mt-2">Couleur: {{ this.vehicule.couleur }}</div>
                    <div class="mt-2">
                      Immatriculation: {{ this.vehicule.immatriculation }}
                    </div>
                    <div class="mt-2">
                      Motorisation: {{ this.vehicule.motorisation }}
                    </div>
                    <div class="mt-2">
                      Climatisation : {{ this.vehicule.climatisation }}
                    </div>
                  </div>
                  <div class="col-md-4 ">
                    <div class="mt-2">
                      Statut actuel : {{ this.vehicule.statut }}
                    </div>
                    <div class="mt-2">
                      Observations : {{ this.vehicule.observations }}
                    </div>
                    <div class="mt-2">
                      Année de mise en circulation :
                      {{ this.vehicule.anneeMiseEnCirculation }}
                    </div>
                    <div class="mt-2">
                      Numéro assurance : {{ this.vehicule.numeroAssurance }}
                    </div>
                    <div class="mt-2">
                      Nombre de Siège : {{ this.vehicule.nbreVehicule }}
                    </div>
                    <div class="mt-2">Modèle : {{ this.vehicule.model }}</div>
                    <div class="mt-2">
                      Type de véhicule : {{ this.vehicule.types }}
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Historique des locations</h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Liste des locations éffectuées</a
                    >
                  </template>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="UrgencesData"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          v-b-tooltip.hover
                          @click="googleMap(row.item)"
                          title="Voir position"
                        >
                          <i class="fas fa-map-marker-alt font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveAlerte"
          >Oui</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
</style>
